// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: 'Roboto', sans-serif;
}

svg path {
    stroke:inherit;
    stroke-width:inherit;
    fill:inherit;
}

.container {
    margin: 0 auto;
    width: 1200px;
}

@media screen and (max-width: 425px){
    .container {
        width: 350px;
    }
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,iCAAiC;EACjC,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;IACI,iCAAiC;AACrC;;AAEA;IACI,cAAc;IACd,oBAAoB;IACpB,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,aAAa;AACjB;;AAEA;IACI;QACI,YAAY;IAChB;AACJ","sourcesContent":["body {\r\n  margin: 0;\r\n  font-family: 'Roboto', sans-serif;\r\n  -webkit-font-smoothing: antialiased;\r\n  -moz-osx-font-smoothing: grayscale;\r\n}\r\n\r\ncode {\r\n    font-family: 'Roboto', sans-serif;\r\n}\r\n\r\nsvg path {\r\n    stroke:inherit;\r\n    stroke-width:inherit;\r\n    fill:inherit;\r\n}\r\n\r\n.container {\r\n    margin: 0 auto;\r\n    width: 1200px;\r\n}\r\n\r\n@media screen and (max-width: 425px){\r\n    .container {\r\n        width: 350px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
